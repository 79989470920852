// Start deneb_about
.deneb_about{
	overflow: hidden;
	.deneb_img_box{
		@media #{$md}{
			margin-bottom: 60px;
		}
		@media #{$xs}{
			margin-bottom: 60px;
		}
	}
	.deneb_content_box{
		margin-left: 20px;
		h2{
			margin-bottom: 40px;
			@media #{$xs}{
				font-size: 28px;
			}
		}
		p{
			padding-bottom: 20px;
		}
		.deneb_btn{
			margin-top: 20px;
			min-width: 155px;
		}
	}
}
.deneb_about{
	.deneb_content_box{
		h2{
			position: relative;
			&:after{
			    position: absolute;
			    content: '';
			    left: -10px;
			    top: -20px;
			    background: url(../images/shape/title.png) no-repeat;
			    display: inline-block;
			    width: 96px;
			    height: 67px;
			    z-index: -1;
			}
		}
	}
	
}
// about_v1
.about_v1{
	overflow: hidden;
	position: relative;
	z-index: 1;
	&:after{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		background: url(../images/shape/shape_8.png) no-repeat;
		background-position: top left;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.deneb_img_box{
		max-width: 470px;
		img{
			position: relative;
			z-index: 9999;
		}
		// &:after{
		// 	position: absolute;
		// 	content: '';
		// 	left: 0;
		// 	bottom: 0;
		// 	background: url(../images/shape/ab.png) no-repeat;
		// 	background-position: bottom left;
		// 	width: 100%;
		// 	height: 100%;
		// }
	}
}
// about_v2
.about_v2{
	padding-top: 90px;
	padding-bottom: 120px;
	.award_box{
		display: flex;
		flex-wrap: wrap;
		.award_img{
			margin-right: 30px;
		}
		.award_info{
			img{
				margin-bottom: 12px;
			}
			h4{
				font-weight: bold;
				color: $theme_color;
				font-size: 20px;
			}
			h5{
				font-size: 20px;
			}
		}
	}
}
// deneb_skill
.deneb_skill{
	overflow: hidden;
	padding-bottom: 120px;
	.section_title{
		margin: 0;
		max-width: 100%;
		margin-bottom: 30px;
	}
	.skill_wrapper{
		overflow: hidden;
		.single_bar{
			margin-bottom: 30px;
			.progress_title{
				h5{
					font-weight: 600;
					margin-bottom: 10px;
				}
				& span{
					float: right;
					color: $theme_color;
				}
			}
			.progress{
				overflow: visible;
				height: 20px;
				background-color: #f6f6f6;
				border-radius: 10px;
				.progress-bar{
					overflow: visible;
					position: relative;
					border-radius: 10px;
					background-color: $theme_color;
					.dot{
						overflow: visible;
						position: absolute;
					    top: 0;
					    right: 0;
						display: inline-block;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-color: #f89e00;
						&:after{
							position: absolute;
						    top: -7px;
						    right: -7px;
						    content: '';
						    width: 33px;
						    height: 33px;
						    border-radius: 50%;
						    background-color: #f89e00;
						    opacity: .2;
						}
					}
				}
			}
		}
	}
}
.deneb_skill{
	.section_title{
		h2{
			position: relative;
			&:after{
			    position: absolute;
			    content: '';
			    left: -20px;
			    top: -20px;
			    background: url(../images/shape/title.png) no-repeat;
			    display: inline-block;
			    width: 96px;
			    height: 67px;
			    z-index: -1;
			}
		}
	}
}
.deneb_experience{
	.section_title{
		h2{
			position: relative;
			&:after{
			    left: 0px;
			}
		}
	}
}
// deneb_experience
.deneb_experience{
	overflow: hidden;
	padding-bottom: 120px;
	.single_experience{
		margin-bottom: 55px;
		&:last-child{
			margin-bottom: 0;
			.icon{
				&:before{
					display: none;
				}
			}
		}
		&:nth-child(even){
			.deneb_info_box{
				text-align: right;
				@media #{$xs}{
					text-align: center;
				}
			}
		}
		.icon{
			@media #{$xs}{
				margin-bottom: 40px;
			}
			position: relative;
			width: 75px;
  			height: 75px;
  			text-align: center;
  			border-radius: 50%;
			background-image: -moz-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
			background-image: -webkit-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
			background-image: -ms-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
  			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
  			margin: 0 auto;
    		padding-top: 17px;
    		&:before{
    			@media #{$xs}{
					display: none;
				}
    			content: '';
			    position: absolute;
			    top: 0;
			    left: 35px;
			    border: 2px dashed #9fa4ab;
			    width: 0;
			    height: 233px;
			    z-index: -1;
    		}
    		&:after{
    			content: '';
				background-image: -moz-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background-image: -webkit-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background-image: -ms-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
    			width: 110px;
  				height: 110px;
  				border-radius: 50%;
  				opacity: 0.302;
  				display: block;
  				position: absolute;
			    left: 50%;
			    top: 50%;
  				margin-top: -55px;
  				margin-left: -55px;
    		}
    		h3{
    			font-size: 35px;
    			color: $white;
    			font-weight: 600;
    		}
		}
		.deneb_text_box{
			@media #{$xs}{
				margin-bottom: 40px;
			}
			position: relative;
			padding: 30px;
			background-color: $white;
			border-radius: 10px;
			box-shadow: 2.5px 4.33px 15px 0px rgba(0, 0, 0, 0.15);
			@include transition(.5s);
			&:hover{
				background-image: -moz-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background-image: -webkit-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				background-image: -ms-linear-gradient( 180deg, rgb(255,189,39) 0%, rgb(254,176,0) 100%);
				box-shadow: 2.5px 4.33px 15px 0px #feb000;
				p{
					color: $white;
				}
			}
			&:after{
				position: absolute;
				content: '';
				top: 40%;
    			right: -69px;
				width: 60px;
			    height: 50px;
			    background: url(../images/pointer.png) no-repeat;
			}
		}
		.deneb_info_box{
			@media #{$xs}{
				text-align: center;
				margin-bottom: 40px;
			}
			h4{
				font-weight: bold;
				font-size: 25px;
				margin-bottom: 10px;
			}
			h5{
				color: #7f8693;
				text-transform: uppercase;
				font-size: 18px;
				margin-bottom: 15px;
			}
			h6{
				font-size: 20px;
				color: $theme_color;
				font-weight: 600;
			}
		}
	}
}
.about-us-two{
	img{
		border-radius: 4px;
	}
	blockquote {
		padding: 30px;
		border-radius: 3px;
		margin-bottom: 30px;
		position: relative;
		background: #fff;
		border-left: 4px solid #feb000;
		-webkit-box-shadow: 0 23px 49px 0 rgba(59, 59, 152, 0.15);
		box-shadow: 0 23px 49px 0 rgba(59, 59, 152, 0.15);
		margin:10px 0px 10px  18px;
		span, strong{
			color: #feb000;
		}
	}
}